(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbDynamicAttributes.directive:wbDynAttrs
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="wbDynamicAttributes">
       <file name="index.html">
        <wb-dyn-attrs></wb-dyn-attrs>
       </file>
     </example>
   *
   */
  angular
    .module('wbDynamicAttributes')
    .directive('wbDynAttrs', wbDynAttrs);

  function wbDynAttrs($parse, _) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var attributes = $parse(attrs.wbDynAttrs)(scope);
        if (_.isArray(attributes)) {
          attributes.forEach(function (attribute) {
            addAttribute(attribute);
          });
        } else {
          addAttribute(attributes);
        }

        function addAttribute(attribute) {
          element.attr(attribute, '');

          if (attributeIsNotCompiled(attribute)) {
            element.addClass(attribute);
          }
        }

        function attributeIsNotCompiled(attribute) {
          return !element.hasClass(attribute);
        }
      }
    };
  }
}());
